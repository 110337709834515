import { GUID } from "@faro-lotv/ielement-types";
import { BackgroundTaskState } from "@faro-lotv/service-wires";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useAppDispatch } from "@store/store-helper";
import {
  FileUploadTask,
  FileUploadTaskContext,
} from "@custom-types/file-upload-types";
import {
  removeOne,
  setOne,
  updateOne,
  updateMany,
} from "@store/upload-tasks/upload-tasks-slice";
import {
  IdAndUpdateTaskProps,
  UploadManagerInterface,
} from "@custom-types/upload-manager-types";
import { UploadManager } from "@context-providers/file-upload/upload-manager";

/**
 * The File Upload Context provides an object that store all ongoing
 * file uploads and allows to manage them.
 *
 * WARNING: this type should not be used directly, it is only needed to implement
 * the FileUploadContextProvider and the hooks useFileUploader and useCancelUpload.
 */
type UploadContext = {
  uploadManager: UploadManagerInterface;
};

/** Context which provides the file upload functionalities */
export const FileUploadContext = createContext<UploadContext | undefined>(
  undefined
);

/**
 * @returns a context useful to start, store monitor and complete file upload tasks.
 *
 * WARNING: this type should not be used directly, it is just exported
 * as an implementation detail to realize the FileUploadContextProvider.
 */
export function useFileUploadContext(): UploadContext {
  const context = useContext(FileUploadContext);
  if (!context) {
    throw new Error("FileUploadContext is not initialized.");
  }
  return context;
}

/**
 * @returns a provider component for the FileUploadContext
 * All children of this component may start, monitor, and finish file uploads
 * and the uploads are stored in the context, therefore they outlive any particular
 * mode or component.
 */
export function FileUploadContextProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const dispatch = useAppDispatch();

  /**
   * Creates a new upload task in store
   *
   * @param id An unique id of the task
   * @param file The file which is uploading
   * @param isSilent Whether progress toast notifications should be hidden
   * @param context Additional information of the file upload task
   */
  const startTaskInStore = useCallback(
    (
      id: GUID,
      file: File,
      isSilent: boolean,
      context: FileUploadTaskContext
    ) => {
      const isoDate = new Date().toISOString();

      const fileUploadTask: FileUploadTask = {
        id,
        fileName: file.name,
        createdAt: isoDate,
        status: BackgroundTaskState.created,
        progress: 0,
        context,
        isSilent,
      };
      dispatch(setOne(fileUploadTask));
    },
    [dispatch]
  );

  /**
   * Updates an upload task in store
   *
   * @params id The unique id of the task
   * @params propsToUpdate An object containing the property to update
   */
  const updateTaskInStore = useCallback(
    (update: IdAndUpdateTaskProps | IdAndUpdateTaskProps[]) => {
      if (Array.isArray(update)) {
        dispatch(updateMany(update));
      } else {
        dispatch(updateOne(update));
      }
    },
    [dispatch]
  );

  /**
   * Remove an upload task from store
   *
   * @params id The unique id of the task
   */
  const removeTaskFromStore = useCallback(
    (id: GUID) => {
      dispatch(removeOne(id));
    },
    [dispatch]
  );

  /**
   * The upload manager used to handle multiple files upload
   */
  const uploadManager = useMemo(
    () =>
      new UploadManager(
        startTaskInStore,
        updateTaskInStore,
        removeTaskFromStore
      ),
    [startTaskInStore, updateTaskInStore, removeTaskFromStore]
  );

  return (
    <FileUploadContext.Provider value={{ uploadManager }}>
      {children}
    </FileUploadContext.Provider>
  );
}
